import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import {
  HalfWidthBlock,
  PaddingWrapper,
  ReferenceContainer,
  TitleH2,
} from "@igloonet-web/shared-ui"
import ReferenceBanner from "@igloonet-web/shared-ui/components/reference/reference-banner"
import ReferenceBannerContent from "@igloonet-web/shared-ui/components/reference/reference-banner-content"

import Logo from "../../../images/reference/ldf/logoldf.svg"
import Top from "../../../images/reference/ldf/top.jpg"
import Header from "../../../images/reference/ldf/header-marketing.png"

class LDF extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    const pozadi = {
      backgroundImage: `linear-gradient(rgba(10,75,54,0.9),rgba(0,0,0,0.9)),url(${Top})`,
    }

    return (
      <Layout>
        <Helmet>
          <title>
            Reference na marketingové práce pro LDF MENDELU | igloonet
          </title>
          <meta
            name="description"
            content="Marketing, vývoj a hosting pod střechou jedné firmy. Programujeme moderní eshopy, poskytujeme prvotřídní hostingové služby a tvoříme marketingové strategie na míru."
          />
        </Helmet>
        <ReferenceBanner
          styles={pozadi}
          logo={Logo}
          alt="logo"
          extodkaz="//mendelu.cz/"
          heading="Reference na marketingové práce pro LDF MENDELU"
          odstavec="2012 - 2016"
        >
          <ReferenceBannerContent
            image={{
              src: Header,
            }}
            mainText="Lesnická a dřevařská fakulta je jednou z pěti fakult Mendelovy univerzity v Brně a zároveň její vlajkovou lodí na poli výzkumu a vývoje krajiny, ekologie a zpracování dřeva. Každý rok přijímá 700 nových uchazečů."
          />
        </ReferenceBanner>

        <ReferenceContainer>
          <HalfWidthBlock>
            <PaddingWrapper>
              <TitleH2>Zadání</TitleH2>
              <ul>
                <li>
                  Primární cíl: navýšit počet uchazečů o bakalářské obory.
                </li>
                <li>Sekundární cíl: zvýšit povědomí o fakultě.</li>
              </ul>
            </PaddingWrapper>
            <PaddingWrapper>
              <TitleH2>Rozsah projektu</TitleH2>
              <p>
                Od roku 2012 jsme spravovali kampaně na sociálních sítích,
                Google Adwords a Seznam Sklik. Pro efektivní měření konverzí
                během kampaně v roce 2013 jsme připravili na míru jednostránkový
                web Modernistudium.cz Responzivně, přehledně a pestře informoval
                o seznamu nabízených oborů a zajímavých benefitech studia na
                LDF.
              </p>
            </PaddingWrapper>
            <PaddingWrapper>
              <TitleH2>Historie spolupráce</TitleH2>
              <p>
                S LDF jsme začali úspěšně spolupracovat v roce 2012. Na jaře
                2013 jsme vyvinuli jedinečnou webovou stránku modernistudium.cz.
                I přes stále se snižující počet absolventů středních škol se
                snažíme jít proti trendu a počet přihlášek zvyšovat. To se nám
                daří, což je důkaz, že cílení a komunikace na poli online
                marketingu se dlouhodobě vyplácí, a zvlášť u mladého publika s
                kvěle funguje.
              </p>
            </PaddingWrapper>
          </HalfWidthBlock>
        </ReferenceContainer>

        <ContactForm contact="adam" />
      </Layout>
    )
  }
}

export default LDF
